import React from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider } from '../../components/layout';
import { stackStyled } from '../../theme';
import { StackOnBreadcrumbs } from '../../atoms';
import { useViewPortSize } from '../../hooks';
import { ContactForm } from '../../components/sections';

const BreadCrumbsContainer = stackStyled('div', (props: { isMobile: boolean, isTablet: boolean }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  maxWidth: '1280px',
  width: props.isMobile ? '90%' : props.isTablet ? '70%' : '100%',
  margin: '0 auto',
  paddingTop: '10px',
  paddingRight: '15px',
  paddingLeft: props.isTablet ? '35px' : '15px',
}));

const ContactUs: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useViewPortSize();

  return (
    <LayoutProvider seo>
      <BreadCrumbsContainer isMobile={isMobile} isTablet={isTablet}>
        <StackOnBreadcrumbs
          pages={[
            { title: 'Home', href: '/' },
            { title: 'Customer service', href: '/customer-service' },
            { title: `Contact us`, href: `/customer-service/contact-us` },
          ]}
        />
      </BreadCrumbsContainer>
      <ContactForm />
    </LayoutProvider>
  );
};

export default ContactUs;
